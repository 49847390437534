.modal {
  max-width: none;
  margin: 0;
  background: none;
  width: 110vmin;
  height: 65vmin;
  display: flex;
  padding: 1vmin 0.5vmax;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: none;
  background-size: 100% 100%;


  &.big {
    width: 120vmin;
    height: 75vmin;
  }

  .modal-title {
    height: 9vmin;
    font-size: 5.5vmin;
    display: flex;
    padding-bottom: 2vmin;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-family: "Neuehelvetivaext";
    // color: #FFC703;
    color: #fff;

    >img {
      height: 60%;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 5vmin;
      text-align: center;
      margin: 0 3vmin;
    }

    h4 {
      display: flex;
      font-size: 4vmin;
      text-align: center;

      &.margin {
        margin-bottom: 8vmin;
      }

      .token-img {
        // background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Spin_Token.png");
        background-size: 100% 100%;
        height: 4vmin;
        width: 4vmin;
        margin-right: 1vmin;
      }
    }

    h5 {
      font-size: 2.5vmin;
      margin-top: 2vmin;
    }
  }

  .modal-button {
    height: 10vmin;
    font-size: 4vmin;

    .btn-close {
      // background-image: url(https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix/btn.png);
      background-size: 100% 100%;
      width: 30vmin;
      height: 8vmin;
      font-size: 3.5vmin;
    }

    .btn-agree {
      background-size: 100% 100%;
      width: 36vmin;
      height: 10vmin;
      font-size: 3vmin;
      margin: 3vmin 0;
    }

    .btn-submit {
      // background-image: url(https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix/btn.png);
      background-size: 100% 100%;
      width: 29vmin;
      height: 8vmin;
      font-size: 3.3vmin;
      color: #452A1C;
    }
  }
}

.modal-message {
  .modal-content {
    padding-top: 4vmin;
    height: 40vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal-button {
    margin-bottom: 6vmin;
    height: 10vmin;
  }
}

.modal-form {
  .modal-content {
    margin: 3vmin 0 1vmin 0;

    .form-container {
      width: 100vmin;
      height: 100%;
      margin: auto auto 0;
      display: flex;
      flex-direction: column;

      .form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1vmin;

        label {
          font-size: 3.5vmin;
          width: 30%;
          display: flex;
          align-items: center;
          text-align: left;
          color: white;
        }


        &>p {
          font-size: 3.5vmin;
          margin-right: 4vmin;
        }

        input {
          background-color: white;
          width: 70%;
          height: 5vmin;
          outline: none;
          border: none;
          padding: 0.1rem 0.15rem;
          font-size: 3vmin;
          border-radius: 0;
          color: black;

          &::placeholder {
            font-style: italic;
          }

          &[type="phone"] {
            width: 70%;
          }
        }

        .group {
          display: flex;
          width: 70%;
          justify-content: space-between;
          align-items: center;
          height: 5vmin;
          margin-bottom: 0;

          select {
            width: 20%;
            height: 5vmin;
            background-color: white;
            font-size: 3vmin;
            color: black;
            border-radius: 0;
          }

          input {
            width: 75%;
          }
        }
      }
    }
  }

  .checkbox-group {
    margin: 2vmin 4vmin 0;
    display: flex;
    flex-direction: column;

    .form-checkbox {
      width: 100%;
      margin: 0 auto 1vmin;
      display: flex;
      align-items: center;

      &:nth-child(5) {
        margin: 1vmin auto 1vmin;
      }

      input[type="checkbox"] {
        width: 3vmin;
        height: 3vmin;
        margin-right: 1vmin;
        border-radius: 0;
      }

      label {
        width: 90%;
        font-size: 2.8vmin;
        color: #F0DC77;
        font-style: italic;
        margin-left: 3vmin;

        .link {
          text-decoration: underline;
        }
      }
    }
  }
}

.modal-congrats {
  .modal-content {
    margin-top: 4vmin;

    .reward {
      padding: 2vmin;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4vmin;
      background-size: 100% 100%;

      .img-reward {
        background-size: 100% 100%;
        width: 12vmin;
        height: 12vmin;
      }
    }

    .congrats-text-desc {
      margin-top: 1.5vmin;
    }
  }

  .modal-button {
    margin-top: 6vmin;
  }
}

.modal-eventinfo {
  .modal-content {
    .event-info {
      overflow-y: scroll;
      font-size: 3vmin;
      width: 100vmin;
      height: 47vmin;
      margin-top: 2vmin;

      ol {
        list-style-type: decimal;
        padding-left: 3vmax;
        margin-top: 2vmin;

        li {
          margin-top: 1vmin;
          text-align: left;
        }
      }
    }
  }
}

.modal-tos {
  .modal-content {
    overflow-y: scroll;
    height: 48vmin;
    margin: 3vmin 0;

    .tos {
      font-size: 3vmin;
      width: 100vmin;

      ol {
        list-style-type: decimal;
        padding-left: 3vmax;
        margin-top: 2vmin;

        li {
          margin-top: 1vmin;
          text-align: left;
        }
      }
    }
  }
}

.modal-share {
  .modal-content {
    height: 50vmin;
    justify-content: center;

    .icon-container {
      display: flex;
      margin-bottom: 4vmin;

      >div {
        width: 12vmin;
        height: 12vmin;
        margin: 0 3vmin;
      }

      .btn-fb {
        // background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Btn_fb.png");
      }

      .btn-wa {
        // background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix/Btn_WA.png");
      }
    }
  }
}

.modal-confirm {
  .modal-content {
    height: 38vmin;
    justify-content: center;

    h4.margin {
      margin-bottom: 4vmin;
    }
  }

  .form-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vmin;
    margin-top: 3vmin;

    input[type="checkbox"] {
      transform: scale(2);
    }

    label {
      margin-left: 4vmin;
      font-size: 3vmin;
      color: #F0DC77;
      font-style: italic;
    }
  }
}
