@font-face {
    font-family: "Roboto";
    src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspinvicee/font/ROBOTO-REGULAR.TTF");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspinvicee/font/ROBOTO-MEDIUM.TTF");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspinvicee/font/ROBOTO-BOLD_0.TTF");
    font-weight: bold;
}

@font-face {
    font-family: "Neuehelvetivaext";
    src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspinvicee/font/SVN-NEUEHELVETICAEXT-BLACK.TTF");
}

@font-face {
    font-family: "Neuehelvetivaext";
    src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspinvicee/font/SVN-NEUEHELVETICAEXT-BLACKITALIC.TTF");
    font-style: italic;
}