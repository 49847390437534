.react-responsive-modal-container {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-responsive-modal-modal {
    overflow-y: visible;
  }
}

.close-icon {
  display: none;
}

.close-button {
  background: var(--img-close) no-repeat top center;
  background-size: 100% 100%;
  width: 4vmin;
  height: 4vmin;
  top: 1vmin;
  right: -3vmax;
  cursor: pointer;
  &:hover {
    filter: brightness(1.2);
  }
}
