.btn {
  border: none;
  cursor: pointer;
  padding: 0 0.1rem;
  color: #000;
  filter: brightness(1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;

  &.disabled {
    filter: grayscale(1);
  }

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
    &.disabled {
      filter: grayscale(1);
      cursor: inherit;
    }
  }
}
