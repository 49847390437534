@import "reset";
@import "animate";
@import "button";
@import "popup";
@import "font";

:root {
    --img-close : url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspinvicee/btn_Close.png");
}

body {
  font-family: "Roboto" !important;
  color: white;
  user-select: none;
  overflow: hidden;
}

@media only screen and (orientation: portrait) {
  html {
    transform: rotate(90deg);
    transform-origin: 50vmin 50vmin;
    width: 100vmax !important;
  }
}

::-webkit-scrollbar {
  width: 0.7vmax;
  margin-left: 1vmin;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #F25E2E;
  // background: rgb(48,29,243);
  // background: #2c219c;
  // background: #fff;
  background: rgba(210,210,210,.2);
  border-radius: 0.8vmin;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #FCC801;
  // background-color:#a718ec;
  //background: #fff012;
  background: #fff;
  border-radius: 0.8vmin;
}
